body {
  margin: 0px;
  font-family: "Poppins", "Segoe UI", sans-serif !important;
}

p {
  margin: 0px;
}

#base {
  min-height: 100vh;
}

::selection {
  background-color: #d2861c;
  /* Change the background color of selected text */
  color: white;
  /* Change the text color of selected text */
}

.text-2xs {
  font-size: 0.5625rem;
  line-height: 0.75rem;
}

.rotate {
  animation: rotate 20s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.move-x {
  animation: move-x 20s linear infinite;
}

@keyframes move-x {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(-500px);
  }
}

.ant-menu-submenu-selected>.ant-menu-submenu-title {
  color: #d2861c !important;
}

.ant-menu-item[data-menu-id*="state=preview"] {
  /* Your styles here */
  color: #1677ff !important;
}

.ant-menu-item-selected[data-menu-id*="state=preview"] {
  /* Your styles here */
  background-color: #1677ff !important;
  color: #F9F9F9d9 !important;
}

.ant-menu-submenu .ant-menu-vertical {
  background-color: #334455 !important;
}

.ant-menu-item-selected {
  cursor: default !important;
}

.ant-menu-item {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.ant-menu-item,
.ant-menu-submenu-title {
  margin-left: 0 !important;
}

.ant-form-item-tooltip {
  margin-left: 8px !important;
}

.ant-card-actions .anticon {
  font-size: 20px !important;
}

.vvoids-datetimepicker .ant-form-item {
  width: min-content;
}

.anticon-question-circle:hover {
  cursor: help;
}

.ant-btn-icon-only {
  border-color: transparent;
}

.ant-upload-resize-banner .ant-upload .ant-upload .ant-image,
.ant-upload-resize-banner .ant-upload .ant-upload,
.ant-upload-resize-banner .ant-upload,
.ant-upload-resize-banner {
  width: 700px !important;
  height: 100px !important;
  margin: 0px !important;
}

.ant-upload-resize-banner .ant-upload .ant-upload .ant-image .ant-image-img {
  width: 696px !important;
  height: 96px !important;
  padding-left: 2px;
  padding-top: 2px;
}

.ant-upload-resize-tile .ant-upload .ant-upload .ant-image .ant-image-img,
.ant-upload-resize-tile .ant-upload .ant-upload .ant-image,
.ant-upload-resize-tile .ant-upload .ant-upload,
.ant-upload-resize-tile .ant-upload,
.ant-upload-resize-tile {
  width: 426px !important;
  height: 200px !important;
  margin: 0px !important;
}

.ant-upload-resize-tile .ant-upload .ant-upload .ant-image .ant-image-img {
  width: 422px !important;
  height: 196px !important;
  padding-left: 2px;
  padding-top: 2px;
}

.ant-upload-resize-sponsor .ant-upload .ant-upload .ant-image,
.ant-upload-resize-sponsor .ant-upload .ant-upload,
.ant-upload-resize-sponsor .ant-upload,
.ant-upload-resize-sponsor {
  width: 100px !important;
  height: 100px !important;
  margin: 0px !important;
}

.ant-upload-resize-sponsor .ant-upload .ant-upload .ant-image .ant-image-img {
  width: 96px !important;
  height: 96px !important;
  padding-left: 2px;
  padding-top: 2px;
}

.force-pop-buttons-left .ant-popconfirm-buttons {
  text-align: start;
  margin-left: 12px;
}

.carrousel-search input {
  background-color: #334455;
  border: solid 1px #1677ff;
}